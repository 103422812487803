.hero-gradient {
  background: linear-gradient(to top, rgba(0, 0, 0, 0.892) 10%, #000000 40%);
}
.card-gradient {
  background: linear-gradient(
    to top,
    #ffffff 0%,
    #ffffff 50%,
    rgba(0, 0, 0, 0.892) 50%,
    rgba(0, 0, 0, 0.892) 100%
  );
}

.primary-white-text {
  color: #ffffff;
}
.primary-black-text {
  color: #000000 !important;
}
.primary-white-bc {
  background-color: #ffffff;
}

.main-box-shadow {
  box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.1);
}

.primary-green-bc {
  background-color: #5ae0a0;
}

.main-border-radius {
  border-radius: 16px;
}
