* {
  margin: 0;
}

a {
  color: #5ae0a0;
}
body {
  overflow-x: hidden;
}
